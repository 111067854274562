import {
  faAngleLeft,
  faAngleRight,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Img from "../../assets/images/img.png";
import React, { useState } from "react";
import Slider from "react-slick";
import GatsbyImageCustom from "../Image/GatsbyImageCustom";

function SampleNextArrow(props) {
  const { onClick } = props;
  // console.log(className);
  return (
    <div className={`slick-arrow custom-next`} onClick={onClick}>
      <FontAwesomeIcon icon={faAngleRight} className="font-light text-gray-400" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  // console.log(className);
  return (
    <div className={`slick-arrow custom-prev`} onClick={onClick}>
      <FontAwesomeIcon icon={faAngleLeft} className="font-light text-gray-400" />
    </div>
  );
}

const OutletInfoSection = (props) => {
  // console.log(props, "props");

  const [imageToShow, setImageToShow] = useState(
    props.data.outlets_gallery_section[0].asset._id
  );
  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    // cssEase: "linear",
    // pauseOnHover: true,
    // arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // centerMode: true,
    // innerWidth: 576,
    className: "lg:w-576 h-32",

    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          //   infinite: true,
          autoplaySpeed: 3000,
          autoplay: true,
          // dots: true,
          //   centerPadding: "300px",
          // className: "centered-slider",
        },
      },

      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          //   centerMode: false,
          //   infinite: true,
          autoplaySpeed: 3000,
          autoplay: true,
          // centerPadding: "90px",
          // className: "centered-slider",
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          infinite: true,
          autoplaySpeed: 3000,
          autoplay: true,
          // centerPadding: "60px",
          // className: "centered-slider",
        },
      },
    ],
  };
  // console.log(props, "out");

  return (
    <div className="bg-white">
      <div className="max-w-6xl mx-auto z-10 py-20">
        <div className="flex flex-col-reverse lg:flex-row justify-between">
          <div className="flex flex-col  justify-center flex-1 p-8">
            {/* <div className=""> */}
            <GatsbyImageCustom
              id={imageToShow}
              className="w-576 h-96 mx-auto mb-6"
              // style={{
              //   filter: "brightness(50%)"
              // }}
            />
            {/* </div> */}
            {/* <div className="flex flex-row"> */}
            <Slider {...settings}>
              {props.data.outlets_gallery_section.map((aa) => {
                return (
                  <div
                    onClick={() => {
                      setImageToShow(aa.asset._id);
                    }}
                    className="w-28 cursor-pointer"
                  >
                    <GatsbyImageCustom
                      id={aa.asset._id}
                      className="w-full h-32 p-1"
                    />
                  </div>
                );
              })}
              {/* <img src={Img}  />
              <img src={Img} className="w-32 h-32" />
              <img src={Img} className="w-32 h-32" />
              <img src={Img} className="w-32 h-32" />
              <img src={Img} className="w-32 h-32" /> */}
            </Slider>
            {/* </div> */}
          </div>
          <div className="flex-1 p-8">
            <div className="uppercase text-md font-extralight mb-4 text-secondary-golden-300">
              An Experince for the senses
            </div>
            <div className="uppercase font-bold text-3xl md:text-5xl mb-4 text-secondary-darkpurple-500">
              {props.data.outlets_highlighted_title}
            </div>
            <div>
              <p className="text-lg">{props.data.outlets_info_desc}</p>
            </div>
            <div className="flex flex-col justify-center pt-10 ">
              {props.data.outlets_info_section.map((xx, idx) => {
                return (
                  <div key={idx} className="flex items-center pb-4">
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-yellow-400 mr-2"
                    />
                    <p className="text-sm">{xx.outlets_info_descs}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutletInfoSection;
