import React from "react";
import GatsbyImageCustom from "../Image/GatsbyImageCustom";
// import { faStar } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const OutletHeaderSection = (props) => {
  // console.log(props, "fgh");
  return (
    <>
      <div className="relative z-10 ">
        <div className="">
          <GatsbyImageCustom
            id={props.data.outlets_main_image.asset._id}
            alt="imgsrc"
            className="w-full outlet_bg_img object-cover h-600"
            // style={{ height: "500px" }}
            // style={{}}
          />
        </div>

        <div className="mx-auto max-w-6xl">
          {/* <div className=""> */}
          <div className="absolute left-0 right-0 top-0 bottom-0 m-auto flex justify-center items-center">
            <div className="px-4 max-w-xl">
              <div className=" font-bold lg:text-6xl md:text-4xl text-2xl text-white text-center">
                {props.data.outlets_highlighted_title}
                {/* Lorem ipsum */}
              </div>
              <div className="text-base lg:text-lg text-white text-center mt-4">
              {props.data.outlets_short_description}
              </div>
            </div>
            {/* <div className="max-w-xl bg-white">
                <div className="p-4">
                  <div>
                    <p className=" lg:text-xl text-base">
                      {props.data.outlets_info_desc}
                    </p>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 pt-6 px-0 ">
                    {props.data.outlets_info_section.map((xx, idx) => {
                      return (
                        <div key={idx} className="flex space-x-3">
                          <FontAwesomeIcon
                            icon={faStar}
                            className="text-yellow-400"
                          />
                          <p className="text-base">{xx.outlets_info_descs}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div> */}
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default OutletHeaderSection;
