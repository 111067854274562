import {
  faEnvelope,
  faMapMarkerAlt,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const OutletMapSection = (props) => {
  // console.log(props,"sad")
  return (
    <div className="bg-white pb-20">
      <div className="max-w-6xl mx-auto ">
        <div className=" relative z-10 shadow-1xl">
          <iframe
            title="maps"
            className="w-full h-96"
            frameBorder="0"
            scrolling="no"
            src={props.data.location}
          ></iframe>
          <div className="hidden md:block absolute top-24 right-24">
            <div className="bg-secondary-darkblue-500 p-2 max-w-xs">
              <div className="border-secondary-golden-500 border p-8">
                <div className="flex text-sm text-white pb-4">
                  <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
                  <div className="ml-4">
                    {props.data.outlets_address[0]._rawChildren[0].text}
                  </div>
                </div>
                <div className="flex text-sm text-white pb-4">
                  <FontAwesomeIcon icon={faEnvelope} size="lg" />
                  <div className="ml-4">{props.data.outlets_email}</div>
                </div>
                <div className="flex text-sm text-white">
                  <FontAwesomeIcon icon={faPhoneAlt} size="lg" />
                  <div className="ml-4">{props.data.outlets_phoneno}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutletMapSection;
