import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../Layout/Layout";
import OutletHeaderSection from "../Outlets/OutletHeaderSection";
// import OutletInfoSection from "../Outlets/OutletInfoSection";
import OutletMapSection from "../Outlets/OutletMapSection";
// import OutletGallerySection from "../Outlets/OutletGallerySection";
import OutletInfoSectionNew from "../Outlets/OutletInfoSectionNew";
// import { useQueryParam, NumberParam, StringParam } from "use-query-params";

const OutletsContent = ({ data, location }) => {
  // console.log("page Content data", data, location);
  // const [foo, setFoo] = useQueryParam("location.pathname", StringParam);

  // if (data.branch === "Andheri") {
  //   console.log(data.branch, "branch");
  //   setFoo("andheri");
  // } else if (data.branch === "SoBo") {
  //   setFoo("sobo");
  // } else setFoo("Dubai");

  // useEffect(() => {
  //   console.log(location, "location");
  //   if (data.pagedata.slug.current === "andheri") {
  //     console.log("andheri", "branch");
  //     setFoo("andheri");
  //   } else if (data.pagedata.slug.current === "sobo") {
  //     console.log("sobo", "branch");
  //     setFoo("sobo");
  //   } else if (data.pagedata.slug.current === "dubai") {
  //     console.log("dubai", "branch");
  //     setFoo("dubai");
  //   }

  //   console.log(foo, "foofoo");
  // }, [location]);

  return (
    <Layout location={location}>
      <Helmet>
        <title>{data.pagedata.meta_title || data.pagedata.title}</title>
        <meta
          property="og:title"
          content={data.pagedata.meta_title || data.pagedata.title}
        />
        <meta
          property="twitter:title"
          content={data.pagedata.meta_title || data.pagedata.title}
        />
        <meta name="description" content={data.pagedata.meta_desc} />
        <meta property="og:description" content={data.pagedata.meta_desc} />
        <meta
          property="twitter:description"
          content={data.pagedata.meta_desc}
        />

        <meta
          name="theme-color"
          content="#144a95"
          media="(prefers-color-scheme: light)"
        />
        <meta
          name="theme-color"
          content="#144a95"
          media="(prefers-color-scheme: dark)"
        />
        <meta name="theme-color" content="#144a95" />
      </Helmet>
      <div>
        <OutletHeaderSection data={data.pagedata} />
        <OutletInfoSectionNew data={data.pagedata} />
        <OutletMapSection data={data.pagedata} />
        {/* <OutletGallerySection data={data.pagedata} /> */}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query GetOutletPage($slug: String) {
    pagedata: sanityOutlets(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      meta_title
      meta_desc
      id
      outlets_highlighted_title
      outlets_info_desc
      outlets_info_section {
        outlets_info_descs
      }
      outlets_phoneno
      outlets_email
      listing_image {
        asset {
          _id
        }
      }
      outlets_address {
        _rawChildren
      }
      outlets_gallery_section {
        asset {
          _id
        }
      }
      outlets_main_image {
        asset {
          _id
        }
      }
      outlets_short_description
      location
    }
  }
`;
export default OutletsContent;
